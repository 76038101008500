import React from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/SEO/SEO'
import DynamicZone from '../components/DynamicZone/DynamicZone'

import { BsArrowRight } from 'react-icons/bs'

const testimonialTemplate = (  { data: { strapi: { testimonial } }, location } ) => {

	const metaDescription = testimonial.summary ? testimonial.summary.replace(/(\r\n|\n|\r)/gm, " ").substring(0, 160) : null

	return (
		<>
			<SEO title={ `${ testimonial.title } | Testimonial ` } description={ metaDescription } pathname={ location.pathname } />

			<div className="article wrapper">
				<div className="article__wrapper">

					<h1 className="article__header">{ testimonial.name }</h1>
					<h2 className="article__sub-header">{ testimonial.company }</h2>

					<div className="article__body-content">
						{ testimonial && testimonial.content &&
							<DynamicZone components={ testimonial.content } />
						}
					</div>

					<div className="article__link-container">
						<h3 className="article__link-heading">Interested in other testimonials?</h3>
						<Link to="/testimonials" className="article__link">
							View testimonials
							<BsArrowRight className="article__link-icon" />
						</Link>
					</div>

				</div>
			</div>
		</>
	)
}

export default testimonialTemplate

export const query = graphql`
	query testimonialQuery($id: ID!) {
		strapi {
			testimonial(id: $id) {
				id
				title
				name
				company
				summary
				thumbnail {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 1000) {
								...GatsbyImageSharpFluid_noBase64
							}
						}
					}
				}
				content {
					... on StrapiQuery_ComponentArticleTextBlock {
						id
						text
					}
					... on StrapiQuery_ComponentArticleImage {
						id
						displayBorder
						rotateImage
						title
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000) {
										...GatsbyImageSharpFluid_noBase64
									}
								}
							}
						}
					}
					... on StrapiQuery_ComponentArticleVideo {
						id
						videoNumber
						videoTitle
					}
					... on StrapiQuery_ComponentArticleQuote {
						id
						author
						quote
					}
				}
			}
		}
	}
`